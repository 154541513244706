import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import BackgroundImage from 'gatsby-background-image'
import contactSVG from '../data/SVG/contact-lenses.svg'
import Fade from 'react-reveal/Fade'



export default function RightBGDiv({isMobile, isDesktop}) {  

    return (
      <div className=''>    
        <div className='flex-l justify-around-l flex-m justify-around-m w-80-l items-center center dont-shrink vh-100'>

            <Fade down={isDesktop} bottom={isMobile} duration={1000} delay={500} distance='60px'>
              <div className='w-50-l w-50-m w-100 fl tc bg-white flex justify-center items-center'>
                <img className='filtered-blue w-30-l w-40-m w-60 pa2 ma2' src={contactSVG} alt='contact lens'/>
              </div>
            </Fade>
            
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1300} distance='60px'>
              <div className="fr pa3 pa4-ns bg-white black-70 w-50-l w-50-m f3">
                  <header className="bb b--black-70 pv4">
                    <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir">Contact lens</h3>
                  </header>
                  <section className="pt5 pb4">
                    <p className="lh-copy measure f4-l f5 mt0">We provide a very sophisticated contact lens service.  This includes fitting of every lens: soft, hard, bifocal, astigmatism and disposable contact lenses.  We have on premises a large inventory of lenses.  Dr. Earl Winer provides thorough follow-up care so his patients always receive a comfortable and clear contact lens experience.</p>
                  </section>
              </div>
            </Fade>

          </div>
        </div>
    )
}