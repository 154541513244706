import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import BackgroundImage from 'gatsby-background-image'
import laserEyeSVG from "../data/SVG/laser-eyev3.svg"
import Fade from "react-reveal/Fade"

export default function RightBGDiv2({ isMobile, isDesktop }) {
  return (
    <div className="">
      <div className="flex-l justify-around-l flex-m justify-around-m w-80-l items-center center dont-shrink vh-100  ">
        <Fade
          down={isDesktop}
          bottom={isMobile}
          duration={1000}
          delay={500}
          distance="60px"
        >
          <div className="bg-white w-50-l w-50-m w-100 fl tc flex items-center justify-center">
            <img
              className="filtered-blue w-20-l w-30-m w-40 pa2 ma2"
              src={laserEyeSVG}
              alt="Laser eye"
            />
          </div>
        </Fade>

        <Fade
          right={isDesktop}
          bottom={isMobile}
          duration={1000}
          delay={isDesktop ? 1300 : 500}
          distance="60px"
        >
          <div className="fr pa3 pa4-ns bg-white black-70 w-50-l w-50-m f3">
            <header className="bb b--black-70 pv4">
              <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir">
                Laser Vision Correction
              </h3>
            </header>
            <section className="pt5 pb4">
              <p className="lh-copy measure f4-l f5 mt0">
                If you are interested in laser vision correction, Dr. Earl Winer
                will be happy to guide you in picking the right surgeon,
                procedure and laser appropriate for you. After the procedure we
                take great care to monitor your eyes for the best possible
                results.
              </p>
            </section>
          </div>
        </Fade>
      </div>
    </div>
  )
}
