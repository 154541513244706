import React, { useState, useLayoutEffect} from "react"
import Layout from '../components/Layout'
import ContactLensDiv from '../components/ContactLensDiv'
import GlassesDiv from '../components/GlassesDiv'
import LaserEyeDiv from '../components/LaserEyeDiv' //Sorry just to lazy to give the first ContactLensDiv props haha
import eyeChartSVG from '../data/SVG/eye-chartv3.svg'
//import glassesSVG from '../data/SVG/thin-glasses.svg'
import Fade from 'react-reveal/Fade'



export default function Practice() {

  //Because of Async nature of state we used normal JS variable for delay.

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth > 769) {
      setIsMobile(false);
      setIsDesktop(true);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    
  }, []);  

  let delay = 0;
  //Check if window exists for gatsby builds
  if (typeof window !== `undefined`) {
    window.innerWidth > 760 ? delay = 1300 : delay = 500;
  }
  
  
  return (
    <div>
      <Layout>
        <div className='w-100 flex flex-column mw9 center'>
          <div className='flex-row-l justify-around-l items-center-l flex-row-m justify-around-m items-center-m w-80-l flex flex-column-reverse w-100 center dont-shrink vh-100'>

            <Fade up duration={1000} delay={delay} distance='60px'>
              <div className="fl pa3 pa4-ns bg-white black-70 w-50-l w-50-m w-100">
                  <header className="bb b--black-70 pv4">
                    <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir">Comprehensive Eye Exams</h3>
                  </header>
                  <section className="pt5 pb4">
                    <p className="lh-copy measure f4-l f5 mt0">Dr. Earl Winer utilizes state of the art equipment to provide you with the most thorough and comprehensive vision and eye health examination.  All of our equipment is computerized, integrated and linked throughout the office to improve efficiency and accuracy.<br/><br/>
                      All information for your eye exam will be stored in your electronic medical record.  Dr. Earl has been paperless for over 20 years.</p>
                  </section>
              </div>
            </Fade> 
            {/* {console.log('isDesktop: ',isDesktop)} */}
            <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={500} distance='60px'>
              <div className='w-50-l w-50-m w-100 fr tc'>
              <img className='filtered-blue w-40-l w-40-m w-60 pa2 ma2' src={eyeChartSVG} alt='eye chart'/>
              </div>
            </Fade>
 
          </div>
          
          <ContactLensDiv isMobile={isMobile} isDesktop={isDesktop}/>
          <GlassesDiv isMobile={isMobile} isDesktop={isDesktop}/>
          <LaserEyeDiv isMobile={isMobile} isDesktop={isDesktop}/>
        </div>
      </Layout>
    </div>
    )
}
