import React from "react"
//import { useStaticQuery, graphql } from "gatsby"
//import BackgroundImage from 'gatsby-background-image'
import glassesSVG from '../data/SVG/thin-glasses.svg'
import Fade from 'react-reveal/Fade'

export default function LeftBGDiv({ isMobile, isDesktop }) {  

    return (
    <div className=''>    

        <div className='flex flex-row-l justify-around-l flex-row-m justify-around-m w-80-l items-center flex-column-reverse center bg-white dont-shrink vh-100'>    
          <Fade bottom={isMobile} left={isDesktop} duration={1000} delay={isDesktop ? 1300 : 500} distance='60px'>
            <div className="fl pa3 pa4-ns bg-white black-70 w-50-l w-50-m w-100 f3">
                <header className="bb b--black-70 pv4">
                  <h3 className="f3 fw7 ttu tracked lh-title mt0 mb3 avenir">Optical Designer Eyeglasses</h3>
                </header>
                <section className="pt5 pb4">
                  <p className="lh-copy measure f4-l f5 mt0">We carry the latest in eyewear styles and designs.  We provide a full warranty on quality during the first year of wear.  Our lenses are of the highest caliber material and optical quality for the very best in vision in the thinnest and lightest possible lenses.</p>
                </section>
              </div>
          </Fade>
          <Fade bottom={isMobile} right={isDesktop} duration={1000} delay={500} distance='60px'>
            <div className='w-50-l w-50-m w-100 fr tc bg-white flex items-center justify-center'>
              <img className='filtered-blue w-40-l w-40-m w-60 pa2 ma2' src={glassesSVG} alt='glasses'/>
            </div>
          </Fade>
        </div>

    </div>
    )
}